<template>
  <div class="app-container">
    <CrudTable
      ref="table"
      entity="actionReport"
      :columns="columns"
      :allow-actions="false"
      :on-row-dbl-click="onRowDblClick"
      selectable-columns
      @search="search"
    />
    <div id="toolbar" class="actions" style="padding: 10px">
      <button class="el-button base-button icon-only" data-cy="edit" @click="downloadReport()">
        Export
      </button>
    </div>
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';
import request from '@/utils/request';
import { saveBlobAs } from '@/utils';
import { convertSearchToQueryParams } from '@/utils/form';

export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      query: '',
      columns: [
        { field: 'dossierID', width: 100, label: 'dossier.dossierID' },
        { field: 'fullName', label: 'common.name' },
        { field: 'creationDate', type: 'date', label: 'dossierAction.creationDate' },
        { field: 'actionTypeName', label: 'dossierAction.actionTypeName' },
        { field: 'comment', label: 'dossierAction.comment' },
        { field: 'actingRoleName', label: 'dossierAction.actingRoleName' },
        { field: 'alertDate', type: 'date', label: 'dossierAction.alertDate' },
        { field: 'actionStatusName', label: 'common.status' }
      ]
    };
  },
  methods: {
    onRowDblClick(row) {
      this.$router.push({
        name: 'EditDossier',
        params: { id: row.dossierID, returnToPage: 'actionReport' }
      });
      this.$emit('edit');
    },
    search(search) {
      this.query = convertSearchToQueryParams(search);
    },
    async downloadReport() {
      const columns = this.columns.filter(c => c.checked).map(c => c.field);

      await request
        .post(`report/ActionReportExport?${this.query}`, { columns: columns }, { responseType: 'blob' })
        .then(results => {
          saveBlobAs(results.data, 'Aktieoverzicht.xlsx');
        });
    }
  }
};
</script>

<style scoped></style>
